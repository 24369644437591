<template lang="pug">
include /mixins
div
  row(nowrap space='none')
    cell(cols='auto')
      loading-wrapper(:loading='loading')
        card
          card-label(
            position='center',
            v-if='formData.is_hold',
            style='position: fixed; z-index: 111100'
          )
            notice
              template(v-slot:icon='')
                icon(type='error', color='warning', size='5xl')
              template(v-slot:caption='')
                v-caption(:variant='["bold", "pre"]', tag='div') {{ _("This show is under maintance.\n It will come back shortly!") }}
          card-panel()
            card()
              card-panel()
                row(column)
                  +b.schema.svg-wrapper--absolute(
                    ref='schema',
                    style='overflow: hidden; background-color: #fff; width: 100%'
                  )
                    +e.filters(ref='filter')
                      //- booking(
                      //-   :show-id='showId',
                      //-   :self-fetch='false',
                      //-   @input='addToCart'
                      //-   @update="filterData(sliderData)"
                      //- )
                      //-   template(slot="filter")
                      //-     div
                      //-       portal-target(name="destination" v-if="isDesktop !== null && !isDesktop")
                      cell.is-hidden-xl(cols='12' v-if='formData.price_descriptor && formData.hall && formData.hall.has_schema')
                        v-caption.card__panel.card__panel--offset_top(
                          ref='title',
                          size='3',
                          :variant='["bold", "center"]'
                        ) {{ show }}
                      card(
                        v-if='formData.price_descriptor && formData.hall && formData.hall.has_schema && !formData.price_descriptor.some((d) => d.ga)'
                      )
                        card-panel
                          row(alignment='center', space='sm', offset)
                            cell(cols='12')
                              row(
                                alignment='baseline',
                                offset,
                                space='sm',
                                overflow='auto'
                              )
                                //- cell(cols='narrow')
                                  //- card(:space='["xl"]')
                                  //-   card-panel(offset='top')
                                  //-     row(alignment='center', offset)
                                  //-       cell(cols='narrow')
                                cell(cols='narrow')
                                  div(class="filter-booking-wrapper")
                                    row(alignment='center', nowrap, offset)
                                      cell(cols='narrow')
                                        v-caption(size='5', variant='bold') {{ currency }} {{ prices[0] }}
                                      cell(cols='narrow')
                                        vue-slider(
                                          v-bind='slider',
                                          v-model='sliderData',
                                          ref='slider',
                                          width='100px',
                                          tooltip='always',
                                          @drag-end='filterData(sliderData)',
                                          @click.native='filterData(sliderData)'
                                        )
                                      cell(cols='narrow')
                                        v-caption(size='5', variant='bold') {{ currency }} {{ prices[prices.length - 1] }}
                                cell(cols='narrow')
                                  card(:space='["md"]')
                                    card-panel(:offset='["horizontal", "bottom"]')
                                      row(
                                        alignment='center',
                                        offset
                                      )
                                        cell(
                                          v-for='(ticket, index) in ticketsLegendDescription',
                                          :key='index'
                                        )
                                          row(alignment='center', space='xs')
                                            cell
                                              icon(
                                                type='circle',
                                                :color='ticket.color'
                                              )
                                            cell(cols='narrow')
                                              v-caption(size='5', variant='bold') {{ ticket.title }}
                    +e.driver(
                      v-if='formData.hall && formData.hall.has_schema'
                    )
                      transition(name='slide-up')
                        info-modal(
                          :style='{ top: infoBlockPosition.top, left: infoBlockPosition.left, zIndex: infoBlockPosition.zIndex }',
                          style='width: 300px; pointer-events: none',
                          :info-blocks='infoBlock',
                          v-if='!!infoBlock || infoBlock.length',
                          :sector-type='sectorType',
                          ref='info-container'
                          :currency='currency'
                        )

                      +e.image-wrapper.image-driver.file-input__img-wrapper(
                        style='overflow: hidden',
                        ref='driver'
                      )
                        +b.file-input__svg--fullheight.schema__image-driver.svg.driver.image(
                          ref='svg'
                        )

                      +e.controls
                        row(alignment='center', column, offset, space='5xs')
                          cell(cols='narrow')
                            v-button(@click='zoomIn', border='whole')
                              v-button-element(variant='success-lite')
                                icon(type='add')
                          cell(cols='narrow')
                            v-button(@click='resetZoom', border='whole')
                              v-button-element(variant='success-lite')
                                v-caption(size='5') reset
                          cell(cols='narrow')
                            v-button(@click='zoomOut', border='whole')
                              v-button-element(variant='success-lite')
                                icon(type='minus')

                    card(v-else-if='formData.hall && !formData.hall.has_schema')
                      +b.booking-cards-wrapper
                        row(offset, space='lg-md')
                          cell(cols='12 4-lg')
                            row(offset, :space='["xs", "md-md"]')
                              cell(cols='12')
                                .card__img-wrapper
                                  img.card__img(
                                    :src='formData.image || "/static/images/poster-stub.png"'
                                  )

                              cell(cols='12')
                                v-caption(size='4', variant='bold') {{ formData.title }}
                              cell(cols='12')
                                row(offset, alignment='center', space='5xs')
                                  cell(cols='narrow')
                                    icon(
                                      type='calendar',
                                      color='warning',
                                      style='line-height: 1'
                                    )
                                  cell(cols='narrow')
                                    v-caption(size='6', color='brand-4') {{ formData.date_at_display }}

                              cell(cols='12')
                                row(offset, space='5xs', alignment='center')
                                  cell(cols='narrow')
                                    icon(
                                      type='pin',
                                      color='danger',
                                      style='line-height: 1'
                                    )
                                  cell(cols='narrow')
                                    v-caption(size='6') {{ formData.hall.title }}

                              cell(cols='12')
                                row(
                                  offset,
                                  alignment='center',
                                  :space='["5xs", "md-md"]'
                                )
                                  cell(cols='narrow')
                                    row(offset, space='5xs', alignment='center')
                                      cell(cols='narrow')
                                        icon(
                                          type='pin',
                                          color='danger',
                                          style='line-height: 1'
                                        )
                                      cell(cols='narrow')
                                        v-caption(size='6') {{ formData.hall.location.address_line_1 }}
                                  //- cell(cols='narrow')
                                  //-   row(
                                  //-     offset
                                  //-     space='5xs'
                                  //-     alignment='center'
                                  //-   )
                                  //-     cell(cols='narrow')
                                  //-       v-caption(size='6') {{ formData.hall.location.city.title }}
                                  //- cell(cols='narrow')
                                  //-   row(
                                  //-     offset
                                  //-     space='5xs'
                                  //-     alignment='center'
                                  //-   )
                                  //-     cell(cols='narrow')
                                  //-       v-caption(size='6') {{ formData.hall.location.subdivision.title }}
                                  //- cell(cols='narrow')
                                  //-   row(
                                  //-     offset
                                  //-     space='5xs'
                                  //-     alignment='center'
                                  //-   )
                                  //-     cell(cols='narrow')
                                  //-       v-caption(size='6') {{ formData.hall.location.country.title }}

                              cell(cols='12', v-if='formData.notice')
                                div(v-html='formData.notice')
                              cell(cols='12', v-if='formData.hall_image')
                                +b.aspect-ratio--ratio_1x1
                                  +e.IMG.element(:src='formData.hall_image')
                          cell(cols='12 6-xl 4-2xl')
                            card
                              +e.panel--offset_vertical(style='overflow: auto')
                                +b.scrollable-wrapper--till-2xl.--full
                                  card
                                    card-panel(:offset='["horizontal-md", "bottom"]')
                                      BookingCards(
                                        :descriptors='groupedBooked',
                                        :id='formData.id',
                                        :show-id='showId'
                                        :is-left-tickets='formData.is_left_tickets'
                                        :currency='currency'
                                      )
    cell(cols='narrow')
      checkout-sidebar(
        :show-id='showId'
        :cart-items='cartItems'
        :get-time='getTime'
        :local-time='localTime'
        :total='total'
        @update='updateScheme($event)'
        @redirect='goToCheckout'
        :currency='currency'
      )
  div.checkout-info
    card(:space='["4xs"]')
      card-panel(offset='bottom')
        card(:space='["4xs"]')
          card-panel(offset='bottom')
            row(alignment='end' space='xs')
              cell
                v-caption(size='6' display='sm' variant='bold') {{ _('Selected seats:') }}
              cell
                v-caption(size='6' display='sm' variant='normal') {{ cartItems.length }}
        row(alignment='end')
          cell(cols='narrow')
            row(alignment='end' space='xs')
              cell
                v-caption(size='6' variant='bold') {{ _('Total:') }}
              cell
                v-caption( size='6' variant='normal') {{ currency }} {{ total }}
          cell(cols='narrow')
            v-caption(size='6' display='sm')
              countdown.checkout-sidebar__watch(
                v-if='!isNaN(localTime)'
                :auto-start='true'
                :time="localTime"
                @progress='transformTime($event)'
                v-slot="{ days, hours, minutes, seconds }"
              )
                row(offset nowrap space='none')
                  cell(cols='narrow')
                    v-caption(size='6') {{ minutes | counter }}
                  cell(cols='narrow')
                    v-caption(size='6') :
                  cell(cols='narrow')
                    v-caption(size='6') {{ seconds | counter }}
    v-button(
      :variant='["accent"]'
      border='whole'
      :class="{'is-disabled': cartItems.length === 0 }"
      @click.prevent='openCartModal'
    )
      v-button-element(size='2sm')
        row(justification='center')
          card(:space='["2xs"]')
            card-panel(offset='right')
              icon(type='cart', color='white')
          v-caption(
            size='6'
            :variant='["uppercase", "bold"]'
          ) {{ _('View cart') }}
</template>

<script>
import { SvgManipulationsHall } from '@cabinet/util/svg'
import axios from 'axios'

import { mapActions, mapState, mapGetters } from 'vuex'
import { groupBy } from '@cabinet/router'

import InfoModal from '@cabinet/components/hall/InfoModal'
// import Booking from '@cabinet/components/booking/Booking'
import CheckoutSidebar from './CheckoutSidebar.vue'
import vueSlider from 'vue-slider-component'
import BookingCards from '@cabinet/components/booking/BookingCards'

import { EventBus } from '@cabinet/components/eventbus/eventbus'

// import { useResponse } from '@cabinet/composables/use-response'
import { /* successHandler, */ errorHandler } from '@cabinet/util'
import HelpersService from '@cabinet/services/api/helpers'
import CartService from '@cabinet/services/api/cart'
import Countdown from './../../components/vendor/Countdown'
import UiInfo from '@cabinet/components/modals/UiInfo'
import GaWrapper from '@cabinet/components/booking/GaWrapper'
import UiOrphanSeats from '@cabinet/components/modals/UiOrphanSeats'
import CartModal from '@cabinet/components/booking/CartModal'

import {
  addClass,
  removeClass,
  getItemFromDOM,
  hasClass,
} from '@cabinet/util/'

// eslint-disable-next-line no-unused-vars
const findOrphanSeatIds = row => {
  return row.reduce((orphans, seat, i) => {
    // If a seat is taken it's not an orphan. Skip it.
    if (seat.isOccupied) return orphans
    const nextSeatTaken = row[i + 1] && (row[i + 1].isOccupied || row[i + 1].isUserOccupied)
    const prevSeatTaken = row[i - 1] && (row[i - 1].isOccupied || row[i - 1].isUserOccupied)
    const isFirstSeat = i === 0
    const isLastSeat = i === row.length - 1

    // Check middle seats
    if (nextSeatTaken && prevSeatTaken) orphans.push(seat)

    // Check first seat
    if (isFirstSeat && nextSeatTaken) orphans.push(seat)

    // Check last seat
    if (isLastSeat && prevSeatTaken) orphans.push(seat)

    return orphans
  }, [])
}

const DESCRIPTOR_FIELDS = [
  'id', 'seat_geom_id', 'sector_geom_id',
  'sector', 'seat_sector', 'status', 'row',
  'sector_descriptor',
]

function convertDescriptors(descriptors) {
  return descriptors.map(
    x => DESCRIPTOR_FIELDS.reduce((acc, field, i) => {
      acc[field] = x[i] || null
      return acc
    }, {})
  )
}

function mergeDescriptors(current, update, remover) {
  const result = [...current]

  update.forEach(item => {
    const index = result.findIndex(x => x.id == item.id)

    if (item.status === 'unreserved') {
      if (index !== -1) {
        const id = item.seat_geom_id
        remover(id)
        result.splice(index, 1)
      }
      return
    }

    if (index !== -1) {
      result[index] = item
    }

    result.push(item)
  })

  return result
}

export default {
  components: {
    InfoModal,
    // Booking,
    vueSlider,
    BookingCards,
    CheckoutSidebar,
    Countdown,
  },
  computed: {
    groupedBooked() {
      const grouped = groupBy(this.booked, ticket => ticket.sector_descriptor)
      this.formData.price_descriptor.forEach((d, i) => {
        d.sectors.forEach((s, s_id) => {
          s.price = d.price
          s.title = d.title
          const sector = grouped[s.id]
          // s.booked = s.booked || 0
          s.free_seats = sector
            ? s.totals_seats - sector.length
            : s.totals_seats
          this.$set(this.formData.price_descriptor[i].sectors[s_id], 'free_seats', sector
            ? s.totals_seats - sector.length
            : s.totals_seats || 0)
          this.$set(this.formData.price_descriptor[i].sectors[s_id], 'booked', s.booked || 0)
        })
      }, [])

      return [...this.formData.price_descriptor]
    },
    ...mapState('cartModule', ['cart', 'fullCart']),
    ...mapState('userModule', ['prefixSlug']),
    sectorType() {
      if (this.infoBlock.length >= 1 && !this.infoBlock[0].s) {
        let type = this._('type')
        let name = this._('fan zone')
        return {
          type,
          name,
        }
      } else if (this.infoBlock.length === 1) {
        let type = this._('row')
        let name = this.infoBlock[0].s.row
        return {
          type,
          name,
        }
      }
    },

    infoBlockPosition() {
      const offset = 40
      if (this.infoBlock.length && this.infoBlock[0].position) {
        const topPosition = this.infoBlock[0].position.y - this.modalDefaultOffset - offset
        return {
          top: `${topPosition > 0 ? topPosition : 0}px`,
          left: `${this.infoBlock[0].position.pageX - 300 / 2}px`,
          zIndex: 100
        }
      }
      return {
        top: 0,
        left: 0,
      }
    },
    ...mapGetters('cartModule', ['getTime']),
    cartItems() {
      return this.fullCart.flat(Infinity)
    },
    total() {
      return this.cartItems.reduce((acc, c) => acc += parseFloat(c.price), 0).toFixed(2)
    },
  },
  props: {
    showId: {
      required: true,
    },
    show: {
      required: true,
    },
    currency: {
      required: true,
    },
  },
  data() {
    return {
      orphan: {},
      formData: {},
      ticketsLegendDescription: [
        {
          title: this._('sold'),
          color: 'booked',
        },
        {
          title: this._('reserved'),
          color: 'danger-lite',
        },
        {
          title: this._('sale'),
          color: 'info',
        },
      ],
      items: [],
      loading: true,
      time: 0,
      panzoom: null,
      height: '600px',
      color: [],
      hallData: [],
      defaultTicketsLegend: [],
      ticketsLegend: [],
      infoBlock: [],
      infoBlockVisible: false,
      setEvents: true,
      booked: [],
      windowWidth: 0,
      canned_at: new Date().toISOString(),
      active: false,
      sale: null,
      sliderData: [0, 0],
      prices: new Set([]),
      interval: null,
      slider: {
        // width: '0',
        // min: 0,
        // max: 0,
        value: 0,
        data: [''],
        disabled: false,
        tooltipStyle: {
          backgroundColor: '#fff',
          color: '#848b9f',
          boxShadow: '0 17px 19px 2px rgba(55, 75, 85, 0.08)',
          borderColor: '#fff'
        },
        processStyle: {
          backgroundColor: '#dc334a',
        },
        bgStyle: {
          backgroundColor: '#eaeaea',
        },
        piecewiseActiveStyle: {
          backgroundColor: '#dc334a',
        },
        piecewiseStyle: {
          boxShadow: '0 0 9px 1px rgba(0, 0, 0, 0.28)',
        },
        formatter: v => `${this.currency} ${v + '.00'}`,
      },
      modalDefaultOffset: 290,
      isDesktop: null,
      redirect: false,
      localTime: 0,
    }
  },
  watch: {
    infoBlock: {
      async handler(nVal) {
        if (nVal.length) {
          await this.$nextTick()
          this.modalDefaultOffset = this.$refs['info-container'].$el.clientHeight
        }
      }
    },
    cart: {
      handler(nval, oval) {
        if (JSON.stringify(nval) != JSON.stringify(oval) && this.active) {
          clearInterval(this.interval)
          this.createInterval()
          oval.forEach(ticket => {
            if (ticket.seat) {
              this.formData.rows_groups[ticket.seat.row].info.forEach(i => {
                i.items.forEach(item => {
                  item.isUserOccupied = false
                  item.isOccupied = false
                })
              })
              let seat = getItemFromDOM(ticket.seat.geom_id)
              if (seat.length) {
                removeClass(seat, 'is-selected')
                removeClass(seat, 'is-reserved')
              }
            }
          })
          // let setOfRows = new Set()

          this.setUserBooked(nval)

          // alert(``)
        }
      },
      deep: true,
    },
    getTime: {
      handler(nval, oval) {
        if (nval != oval && !this.admin) {
          this.localTime = nval
        } else {
          this.localTime = parseFloat(nval) * 1000 - new Date().getTime()
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('ticketsModule', ['fetchTicketsData']),
    ...mapActions('hallModule', ['getSectorsList', 'getSectorData']),
    ...mapActions('vendorsModule', ['fetchVendors']),
    ...mapActions('showsModule', ['fetchShow']),
    ...mapActions('cartModule', ['addItem', 'removeItem', 'fetchCart', 'fetchMainCart']),

    zoomIn() {
      let {
        width: mainWidth,
        height: mainHeight,
      } = this.$refs.driver.getBoundingClientRect()
      window.panzoom.smoothZoom(mainWidth / 2, mainHeight / 2, 1.1)
    },
    zoomOut() {
      let {
        width: mainWidth,
        height: mainHeight,
      } = this.$refs.driver.getBoundingClientRect()
      window.panzoom.smoothZoom(mainWidth / 2, mainHeight / 2, 0.9)
    },
    resetZoom() {
      window.panzoom.zoomAbs(0, 0, 1)
      window.panzoom.moveTo(0, 0)
      window.panzoom.zoomAbs(0, 0, 1)
    },

    styleSvgs() {
      let [sectorsSvg, seatsSvg, serviceSvg] = [
        ...this.$refs.svg.querySelectorAll('svg'),
      ]
      serviceSvg.style['pointer-events'] = 'none'
      sectorsSvg.style['pointer-events'] = 'none'
      // seatsSvg.style['pointer-events'] = 'none'
      // seatsSvg.style.opacity = 0
      sectorsSvg.removeAttribute('class', 'hideitem')
      return {
        sectorsSvg,
        seatsSvg,
        serviceSvg,
      }
    },
    setResizeHandler() {
      try {
        this.windowWidth = window.innerWidth
        window.addEventListener('resize', () => {
          this.windowWidth = window.innerWidth
        })
      } catch (error) {
      }
    },
    async placeSvg(sector, seats) {
      let { sectors_file, seats_file, elements_file } = this.formData.hall
      let images = []
      try {
        images = await Promise.all([
          axios.get(sectors_file),
          axios.get(seats_file),
          axios.get(elements_file),
        ])
      } catch (error) {
      }

      let sectorSvg = images[0].data
      let seatsSvg = images[1].data
      let serviceSvg = images[2].data
      // seatsSvg
      let { svg } = this.$refs
      svg.insertAdjacentHTML('beforeend', sectorSvg)
      const sectors = svg.querySelector('svg')
      const text = [...sectors.querySelectorAll('text')]
      text.forEach(t => t.style.display = 'none')
      svg.insertAdjacentHTML('beforeend', seatsSvg)
      svg.insertAdjacentHTML('beforeend', serviceSvg)
    },
    async placeImages() {
      window.manipulations = new SvgManipulationsHall('.driver')
      let polies = await window.manipulations.init(false)
      let { sectorsSvg /* seatsSvg */ } = this.styleSvgs()

      this.activateSeats()
      window.panzoom.on('zoom', e => {
        let scale = e.getTransform().scale
        const value = 1
        if (scale > value && !hasClass(sectorsSvg, 'is-activated')) {
          addClass(sectorsSvg, 'is-activated')
          this.activateSeats()
        } /* else if (scale <= value && hasClass(sectorsSvg, 'is-activated')) {
          this.deactivateSeats()
        } */
      })
      return polies
    },
    setHolding(value = false) {
      this.formData.is_hold = value
      this.loading = value
    },
    holdChangeHandler(hold) {
      if (this.formData.is_hold != hold && !hold) {
        this.setHolding(false)
        this.$modal.show(
          UiInfo,
          
          {
            text: this._('This show is ready for booking. After the modal would be closed the page would be reloaded.')
          },
          {
            height: 'auto',
            adaptive: true,
            width: 310,
          },
          {
            'before-close': () => location.reload()
          }
        )
        return true
      }

      if (hold && !this.formData.is_hold) {
        this.setHolding(true)
        return true
      }

      return false
    },

    updateBooked(booked) {
      this.booked = booked
      this.booked.forEach(
        item => this.makeLayoutAdjustments(item)
      )
    },

    async loadBooked() {
      return this.fetchTicketsData({ id: this.formData.id })
      .then(({ data: descriptors, meta }) => {
        this.holdChangeHandler(meta.is_holding)
        this.canned_at = meta.canned_at
        this.updateBooked(convertDescriptors(descriptors))
      }).catch(err => console.error(err))
    },
    
    async loadBookedUpdate({ canned_at = this.canned_at } = {}) {
      return this.fetchTicketsData({ id: this.formData.id, updated_since: canned_at })
        .then(({ data: descriptors, meta }) => {
          this.holdChangeHandler(meta.is_holding)
          this.canned_at = meta.canned_at
          this.updateBooked(mergeDescriptors(this.booked, convertDescriptors(descriptors), this.updateScheme))
          if (descriptors.length) this.fetchMainCart()
        }).catch(err => console.error(err))
    },

    initialBookedLoad() {
      this.loadBooked().then(() => this.loadBookedUpdate())
    },

    makeLayoutAdjustments(item) {
      if (!item.seat_geom_id) return
      let element = getItemFromDOM(item.seat_geom_id)

      const group = this.formData.rows_groups[item.row]

      if (item.status === 'sold') {
        addClass(element, 'is-booked')
      } else {
        if (item.seat_geom_id) {
          let isInCart = this.cart.find(
            i => i.seat && i.seat.geom_id === item.seat_geom_id
          )
          if (isInCart) {
            addClass(element, 'is-user-reserved')
          }
          {
            addClass(element, 'is-reserved')
          }
        }
      }
      group.info.forEach(_item => {
        let properItem = _item.items.find(item_ => {
          return item_ && item_.geom_id === item.seat_geom_id
        })

        const itemFromCart = this.cart.find(
          i => i.seat && i.seat.geom_id === properItem.geom_id
        )

        if (properItem) {

          this.$set(
            properItem,
            itemFromCart && 'isUserOccupied',
            true
          )

          this.$set(
            properItem,
            'isOccupied',
            true
          )
        }

      })
    },

    createInterval() {
      const TIME = 5000
      this.interval = setInterval(async () => {
        this.loadBookedUpdate()
      }, TIME)

      this.$once('hook:beforeDestroy', () => {
        clearInterval(this.interval)
      })
    },
    setInfo(e, _sectors) {
      this.infoBlock = _sectors.map(s => {
        return {
          position: e,
          price: s.price,
          title: s.title,
          color: s.color,
          sector: s.sector
        }
      })
    },
    setGaListeners() {
      // const grouped = groupBy(this.booked, ticket => ticket.sector_descriptor)
      const sectors = this.formData.price_descriptor.reduce((sectors, desc) => {
        desc.sectors.forEach(s => {
          s.booked = s.booked || 0
          sectors[s.sector.geom_id] = sectors[s.sector.geom_id] || []
          sectors[s.sector.geom_id].push({
            ...s,
            price: desc.price,
            color: desc.color,
            title: desc.title,
            itemID: s.sector.id,
            show: {
              id: this.formData.id
            }
          })
        })

        return sectors
      }, {})

      Object.keys(sectors).forEach(geom_id => {
        const _sectors = sectors[geom_id]
        const sectorFromDom = getItemFromDOM(geom_id)
        sectorFromDom.style.fill = _sectors[0].color.color
        if (window.innerWidth >= 1200) {
          sectorFromDom.addEventListener('mouseenter', e => this.setInfo(e, _sectors))
          sectorFromDom.addEventListener('mouseleave', e => this.setInfo(e, []))
        }
        sectorFromDom.addEventListener('click', e => {
          // if (_sectors.length === 1) {
          //   const items = _sectors.reduce((items, item) => {
          //     const _items = new Array(parseInt(1)).fill({ type: 'sector', id: item.id })
          //     items.push(..._items)
          //     return items
          //   }, [])
          //   if (!items.length) {
          //     return
          //   }
          //   // this.loading = true
          //   return this.addItems(items)
          //     .then(res => {
          //       this.addToCart()
          //     })
          //     .catch(err => this.loading = false)
          // }
          this.$modal.show(
            GaWrapper,
            
            {
              items: _sectors,
              deletable: false,
              fn: this.addItems,
              isLeftTickets: this.formData.is_left_tickets,
              booked: this.booked,
              onAdd: this.addToCart,
              redirect: this.setRedirect,
            },
            {
              height: 'auto',
              adaptive: true,
              scrollable: true,
              width: 320,
            }
          )
        })

        this.$once('hook:beforeDestroy', () => {
          sectorFromDom.removeEventListener('mouseenter', e => this.setInfo(e, _sectors))
          sectorFromDom.removeEventListener('mouseleave', e => this.setInfo(e, _sectors))
        })
      })
    },
    setLegend(descriptors) {
      let _desc = descriptors
      _desc
        // .sort((a, b) => a.price - b.price)
        .forEach(ticket => {
          let { color, price, seats, title } = ticket
          ticket.color = this.colors.find(c => c.id == color)
          this.prices.add(price)
          this.slider.data.push(price)

          this.setItemListeners(seats, { price, title, color: ticket.color })
        })
      this.setGaListeners()

      this.ticketsLegend = _desc
      this.setStartPrice()
    },
    setItemListeners(figures, { price, title, color } = {}) {
      figures.forEach(s => {
        let id = s.sector.geom_id ? s.sector.geom_id : s.geom_id

        if (id) {

          let seat = getItemFromDOM(id)
          seat.style.fill = color.color
          seat.style.cursor = 'pointer'

          if (window.innerWidth >= 1200) {
            seat.onmouseenter = e => {
              const item = this.booked.some(i => i.seat_geom_id == s.geom_id)
              const data = {
                s,
                position: e,
                price: s.finalPrice || price,
                title,
                color,
              }
              if (item) {
                data.s.booked = true
              }
              this.infoBlock = [data]
            }
            seat.onmouseleave = e => {
              this.infoBlock = []
            }
          }
          seat.onclick = e => {
            // const item = this.booked.some(i => i.seat_geom_id == s.geom_id)
            if (hasClass(seat, 'is-booked')) {
              return
            }
            let type = s.sector.geom_id ? 'sector' : 'seat'


            let itemID = s.sector.geom_id ? s.sector.id : s.id
            if (
              hasClass(seat, 'is-selected') ||
              hasClass(seat, 'is-user-reserved')
            ) {
              this.removeTicket(s.seatId, seat, s)
              s.seatId = null
            } else {
              if (hasClass(seat, 'is-reserved')) {
                return
              }
              if (window.innerWidth > 1200) {
                this.triggerBookModal(
                  itemID,
                  { price: s.finalPrice || price, title, color, seat: seat },
                  type,
                  s
                )
              } else {
                this.triggerBookModal(
                  itemID,
                  { price: s.finalPrice || price, title, color, seat: seat },
                  type,
                  s
                )
              }
            }
          }
        }
      })
    },

    addItems(args) {
      console.log(args);
      if (args) {
        return this
          .addItem({ items: { items: args }, id: this.formData.id })
          .catch((err) => {
            let { data: errors } = err.response
            console.log(errors)
            errorHandler(errors, null, err.response, this)
            this.loading = false
          })
      }
      this.orphan = {}
    },

    triggerBookModal(itemID, seat, type, s) {
      const reserve = { ...s }
      if (reserve.title) {
        reserve.seat = {
          title: reserve.title,
        }
      }
      reserve.title = seat.title
      reserve.price = seat.price
      reserve.color = seat.color.color
      return this.addTicket(itemID, seat.seat, type)
      // import('@cabinet/components/booking/BookedItem').then(
      //   ({ default: Component }) => {
      //     this.$modal.show(
      //       Component,
      //       {
      //         item: reserve,
      //         ticket: { itemID, seat: seat.seat, type },
      //         deletable: false,
      //         fn: this.addTicket,
      //         redirect: this.setRedirect,
      //         // fn: this.promiseHandler({ items }),
      //       },
      //       {
      //         height: 'auto',
      //         adaptive: true,
      //         width: 310,
      //       }
      //     )
      //   }
      // )
    },
    removeTicket(id, seat, s) {
      this.removeItem({
        id: this.showId,
        tickets: {
          tickets: [id],
        },
      })
        .then(res => {
          removeClass(seat, ['is-user-reserved', 'is-reserved', 'is-selected'])
        })
        .catch(err => {
          console.error(err)
        })
    },
    addTicket(id, seat, type) {
      this.addItem({
        items: {
          items: [
            {
              id,
              type,
            },
          ],
        },
        id: this.formData.id,
      })
        .then(res => {
          addClass(seat, 'is-user-reserved')
          this.addToCart()
        })
        .catch(err => {
          let { data: errors } = err.response

          console.log(errors)
          errorHandler(errors, null, err.response, this)
        })
    },
    setStartPrice() {
      const localPrices = [...this.prices].sort(
        (a, b) => parseFloat(a) - parseFloat(b)
      )

      this.slider.min = parseFloat(Math.min.apply(null, localPrices))
      this.slider.max = parseFloat(Math.max.apply(null, localPrices))
      this.sliderData = [parseFloat(localPrices[0]), parseFloat(localPrices[localPrices.length - 1])]

      this.slider.data = [...localPrices]
      setTimeout(() => {
        this.$refs.slider.setIndex([0, localPrices.length - 1])
      }, 100)
    },

    filterData(data) {
      this.ticketsLegend
        // .filter(i => i.price < data[0] || i.price > data[1])
        .forEach(d => {
          d.seats.forEach(s => {
            if (parseFloat(d.price) < parseFloat(data[0]) || parseFloat(d.price) > parseFloat(data[1])) {
              addClass(getItemFromDOM(s.geom_id), 'is-passive')
            } else {
              removeClass(getItemFromDOM(s.geom_id), 'is-passive')
            }
          })
          d.sectors.forEach(s => {

            if (parseFloat(d.price) < parseFloat(data[0]) || parseFloat(d.price) > parseFloat(data[1])) {
              addClass(getItemFromDOM(s.sector.geom_id), 'is-passive')
            } else {
              removeClass(getItemFromDOM(s.sector.geom_id), 'is-passive')
            }
          })
        })
    },
    activateSeats() {
      let { svg } = this.$refs
      let [sectors, seats] = [...svg.querySelectorAll('svg')]
      removeClass(seats, 'is-hidden')
      seats.style.opacity = 1
      seats.style.pointerEvents = 'all'
      let sects = [...sectors.querySelectorAll('polygon')]
      sects.forEach(s => {
        addClass(s, 'is-hidden')
      })
      this.formData.price_descriptor.forEach(desc => {
        desc.sectors.forEach(s => {
          if (s.sector.geom_id) {
            let sector = getItemFromDOM(s.sector.geom_id)
            let classList = sector.classList
            classList.remove('is-hidden')
            classList.add('is-visible')
          }
        })
      })
      addClass(sectors, 'is-active')
    },

    setUserBooked(val) {
      val.forEach(ticket => {
        if (ticket.seat) {
          let _ticket = this.formData.price_descriptor.find(d => {
            return d.title === ticket.title
          })

          if (_ticket) {
            _ticket = _ticket.seats.find(seat => {
              return seat.id === ticket.seat.id
            })
            _ticket.seatId = ticket.id

            let seat = getItemFromDOM(ticket.seat.geom_id)
            removeClass(seat, 'is-reserved')
            addClass(seat, 'is-selected')
          }
        }
      })
    },
    setDiscount() {
      let descriptors = this.formData.price_descriptor

      this.formData.sale.forEach(s => {
        descriptors.forEach(d => {
          let price = parseFloat(d.price)
          let ratio = parseFloat(s.sale_value)
          s.seats.forEach(seat => {
            if (d.seats.length) {
              let item = d.seats.find(s => s.id === seat)
              if (item) {
                item.finalPrice = this.calcAmount(price, ratio, s.mechanics)
                addClass(getItemFromDOM(item.geom_id), 'is-discount')
              }
            }
          })
          s.sectors.forEach(sector => {
            if (d.sectors.length) {
              let item = d.sectors.find(s => s.sector.id === sector)
              if (item) {
                item.finalPrice = this.calcAmount(price, ratio, s.mechanics)
                addClass(getItemFromDOM(item.sector.geom_id), 'is-discount')
              }
            }
          })
        })
      })
    },
    calcAmount(price, saleValue, type) {
      return type === 'ratio' ? price - (price / 100 * saleValue) : price - saleValue
    },
    resize() {
      let paddingTop = document
        .getElementsByClassName('header')[0]
        .getBoundingClientRect().height
      this.$refs.schema.style[
        'min-height'
      ] = `calc(100vh - ${paddingTop /*+  filterHeight */ + 16 * 4}px)`
    },
    async addToCart() {
      if (this.formData.is_orphans) {
        this.checkOrphans()
          .then(({ valid }) => {
            valid ? this.mergeShowCart() : () => { }
          })
          .catch((err) => {

            this.showIllegal()
          })
      } else {
        this.mergeShowCart()
      }

    },
    async mergeShowCart() {
      if (!this.redirect) this.loading = true
      CartService.mergeShowCart({ id: this.showId })
        .then(() => {
          if (this.redirect) {
            location.pathname = `${this.prefixSlug}/checkout/`
          } else {
            this.fetchMainCart()
            this.loading = false
          }
        })
        .catch((err) => {
          let { data: errors } = err.response
          errorHandler(errors.errors, null, err.response, this)
          this.loading = false
        })
    },
    updateScheme(seat) {
      removeClass(getItemFromDOM(seat), ['is-user-reserved', 'is-reserved', 'is-selected'])
    },
    goToCheckout() {
      location.pathname = `${this.prefixSlug}/checkout/`
    },
    checkOrphans() {
      return new Promise((resolve, reject) => {
        let rows = this.booked.reduce((acc, item) => {

          const row = this.formData.rows_groups[item.row]

          if (item.row && row) {
            row.info = row.info.filter(i => i.items.find(i => i.geom_id === item.seat_geom_id && (i.isUserOccupied || i.isOccupied)))
            acc[item.row] =
              acc[item.row] ||
              row
          }
          return acc
        }, {})

        const notLegalArray = []



        Object.keys(rows).forEach(rowId => {
          rows[rowId].info.filter(info => info).forEach(info => {
            notLegalArray.push(...findOrphanSeatIds(info.items))
          })
        })

        if (notLegalArray.length) {
          return reject({ valid: false })
        }
        resolve({ valid: true })
      })
    },
    showIllegal() {
      this.$modal.show(
        UiOrphanSeats,
        {},
        {
          height: 'auto',
          adaptive: true,
          width: 310,
        },
      )
    },
    setRedirect(value) {
      this.redirect = value
    },
    openCartModal() {
      this.$modal.show(
        CartModal,
        {
          hall: this.formData.hall,
          title: this.formData.title,
          date: this.formData.date_at_display,
          time: this.formData.time_at_display,
          redirect: this.goToCheckout,
        },
        {
          height: 'auto',
          adaptive: true,
          width: '100%',
        },
      )
    },
    transformTime({ minutes, seconds }) {
      const SECONDS = 60
      const MILLISECONDS = 1000
      const currentTime = minutes * SECONDS * MILLISECONDS + seconds * MILLISECONDS
      this.$store.commit('cartModule/SET_CURRENT_TIMET_VALUE', currentTime)
    }
  },

  async created() {
    if (screen.orientation) {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        screen.orientation.addEventListener('change', function () {
          if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen()
          } else if (document.documentElement.webkitRequestFullScreen) {
            document.documentElement.webkitRequestFullScreen()
          }
          screen.orientation.lock("portrait-primary")
        })
      }
    }

    this.colors = (await HelpersService.getColors()).data.data
    try {
      this.formData = await this.fetchShow({
        id: this.showId,
        query: '?additional_hall=1&additional_info=1&additional_nested=1',
      })
      this.formData.rows_groups = this.formData.rows_groups.reduce(
        (acc, group) => {

          acc[group.id] = group
          return acc
        }, {}
      )

    } catch (err) {
    }

    if (this.formData.hall.has_schema) {
      await this.placeSvg()
      this.setLegend(this.formData.price_descriptor)
      this.setResizeHandler()
      await this.placeImages()
      this.setDiscount()
    }
    this.initialBookedLoad()
    this.createInterval()
    this.active = true
    this.fetchCart(this.formData.id)

    EventBus.$on('input', () => {
      this.loadBookedUpdate()
    })

    EventBus.$on('change', data => {
      this.ticketsLegend.forEach(legend => {
        legend.info.forEach(info => {
          if (info.sector.id == data.id) {
            info.free_seats =
              data.type === 'add'
                ? (info.free_seats += 1)
                : (info.free_seats -= 1)
            if (info.free_seats < 0) {
              info.free_seats = 0
            }
          }
        })
      })

      // this.loadBookedUpdate()
    })
    this.resize()
    if (!this.formData.is_hold) {
      this.loading = false
    }
    this.isDesktop = window.matchMedia('(min-width: 768px)').matches
  },

  async mounted() {
    window.onresize = this.resize
    const url = location.href
    localStorage.setItem('url', url)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
  },
}
</script>

<style>
circle {
  cursor: pointer !important;
}
</style>
