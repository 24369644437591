<template lang='pug' function>
  include /mixins
  +b.SECTION.section--main(ref='content')
    +e.el(ref='componentFocusTarget')
    router-view(:show-id='showId' :show='show', :currency='currency').child-view
      template(v-slot:site-rules='')
        slot(name='site-rules')
</template>

<script>
  export default {
    props: {
      showId: {
        required: false
      },
      user: {
        required: false
      },
      show: {
        required: false
      },
      currency: {
        required: false,
        type: String,
        default: 'USD',
      },
    },
  }
</script>