<template lang="pug">
card
  card-panel(offset='full')
    card
      card-panel(:offset='["bottom_md", "bottom_xl-md"]')
        row(offset alignment="center")
          //- cell(cols='12 narrow-sm')
          //-   v-select(
          //-     :options='options'
          //-     :val='action'
          //-     :placeholder='_(`c Action`)'
          //-     :allow-empty='true'
          //-     v-model='action'
          //-     :disabled='!selected.length'
          //-     size='lg'
          //-   ).multiselect--width_fixed-sm
          //- +b.control-box
          //-   v-button(
          //-     variant='danger'
          //-     border='whole'
          //-     @click='addTour'
          //-   )
          //-     template(v-slot:prepend='')
          //-       cell
          //-         v-button-element(
          //-           size='lg'
          //-           variant="warning-lite"
          //-         )
          //-           v-caption(variant='bold')
          //-             icon(type='add'
          //-                 size='md')
          //-     cell(cols='auto')
          //-       v-button-element(size='lg') 
          //-         v-caption(
          //-           type='bold' 
          //-           size='4'
          //-         ) {{ _('Add new tour') }}
          cell(cols='12 auto-md 7-lg 6-xl 5-2xl')
            ui-search(@input='fetchUsers', @open='visible = !visible')
              template(v-slot:activator='{ data, send }')
                cell(cols='12 narrow-md')
                  v-button(
                    size='lg',
                    :border='["top_right", "bottom_right"]',
                    @click='send(data)'
                  )
                    cell(cols='auto')
                      v-button-element(size='lg') 
                        v-caption(:variant='["bold", "uppercase"]', size='6') {{ _("s search") }}

              template(v-slot:additional-filters='{ data, send }')
                ui-filter(
                  ref='filter',
                  @input='send',
                  :users='true',
                  :data='data',
                  :visibility='visible',
                  :service='$store.state.userModule.userData.is_vendor && service.fetchOptions'
                )

          cell(cols='12 narrow-md')
            v-button(
              @click='download',
              :variant='["clear", "narrow-md"]',
              alignment='center',
              :disabled='!selected.length'
            )
              cell(cols='narrow')
                v-button-element(size='sm')
                  row(offset, space='xs', alignment='center')
                    cell(cols='narrow')
                      icon(type='upload', color='default')
                    cell(cols='narrow')
                      v-caption(size='4', color='default', variant='bold') {{ _("c export") }}
          cell(cols='12 narrow-md')
            v-button(
              @click="openSmsModal"
              :variant='["clear", "narrow-md"]',
              alignment='center',
              :disabled='!selected.length'
            )
              cell(cols='narrow')
                v-button-element(size='sm')
                  row(offset, space='xs', alignment='center')
                    cell(cols='narrow')
                      icon(type='send', color='default')
                    cell(cols='narrow')
                      v-caption(size='4', color='default', variant='bold') {{ _("send SMS") }}
          cell(justification='right')
            //- v-button(:variant='["clear", "narrow"]')
            //-   cell(cols='narrow')
            //-     v-button-element(size='lg')
            //-       row(offset space='xs' alignment='center') 
            //-         cell()
            //- pagination(:pagination='meta' @set='fetchUsers({...filterData, page: $event })')

      card-panel
        row(offset)
          cell(cols='12', v-if='users.length')
            card
              card-panel(offset='vertical') 
                UsersTable(
                  :users='users',
                  :action-delete='deleteTriger',
                  @input='selected = $event'
                )
              card-panel(
                offset='vertical',
                v-if='meta && meta.limit < meta.total'
              ) 
                ui-pagination(
                  :offset='meta.offset',
                  :total='meta.total',
                  :limit='meta.limit',
                  :current='meta.currentPage',
                  @input='fetchUsers({ ...filterData, page: $event })'
                )

          cell(cols='12', v-else)
            notice
              template(v-slot:icon='')
                icon(type='error', color='warning', size='5xl')
              template(v-slot:caption='')
                v-caption(variant='bold') {{ _("List is empty") }}
          //- v-if='pagination.total > pagination.limit'
</template>

<script>
import { ref, /* onMounted, */ watch, computed } from '@vue/composition-api'
import { useResponse } from '@cabinet/composables/use-response'
import UserService from '@cabinet/services/api/users'
import AgentsService from '@cabinet/services/api/agents'
import usePagination from '@cabinet/composables/use-pagination'

import UiFilter from '@cabinet/components/forms/UiFilter'
import UiSearch from '@cabinet/components/forms/UiSearch'
import Pagination from '@cabinet/components/vendor/Pagination'
import UsersTable from '@cabinet/components/users/UsersTable'
import UiPagination from '@cabinet/components/vendor/UiPagination'
import SmsModal from '@cabinet/components/modals/SmsModal'

export default {
  components: {
    UiFilter,
    UiSearch,
    Pagination,
    UsersTable,
    UiPagination,
    SmsModal,
  },
  setup(p, c) {
    const loading = ref([])
    const users = ref([])
    const meta = ref({})
    const visible = ref(false)
    const selected = ref([])
    const action = ref(null)
    const deleteTriger = ref(false)
    
    const options = ref([
      {
        title: c.root._('Delete customer(s)'),
        id: 1,
      },
    ])

    const filterData = ref({})

    const service = computed(() => {
      return c.root.$store.state.userModule.userData.is_vendor
        ? AgentsService
        : UserService
    })

    const download = async () => {
      try {
        await useResponse(
          UserService.export(selected.value),
          c.root,
          c.refs.observer
        )
      } catch (error) {
        console.error(error)
      }
    }

    const deleteSelect = () => {
      deleteTriger.value = !deleteTriger.value
    }

    const fetchUsers = async (data) => {
      try {
        filterData.value = data
        const res = await useResponse(
          service.value.fetch(data),
          c.root,
          c.refs.observer
        )
        meta.value = usePagination(res.meta.pagination)
        users.value = res.data
        deleteSelect()
      } catch (err) {
        console.error(err)
      }
    }

    watch(selected, (v) => {
      if (!v.length) {
        action.value = null
      }
    })

    const openSmsModal = () => {
      c.root.$modal.show(SmsModal, {
        selected: selected.value,
      }, {
        height: 'auto',
        adaptive: true,
        scrollable: true,
        width: 310,
      })
    }

    // onMounted(() => {
    //   fetchUsers()
    // })
    return {
      loading,
      users,
      meta,
      visible,
      options,
      action,
      selected,
      filterData,
      service,
      deleteTriger,
      fetchUsers,
      download,
      openSmsModal,
    }
  },
}
</script>