import Api from '@cabinet/services'


export default {
  fetch(data) {
    return Api().get('/customers/list/', {
      params: data
    })
  },
  fetchOne(id) {
    return Api().get(`/customers/${id}/`, {
    })
  },
  export(data) {
    return window.open(`/api/v1/customers/export/?ids=${data}`)
  }
}
