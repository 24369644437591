<template lang="pug">
  include /mixins
  card
    +e.panel--offset_vertical(style='overflow: hidden')
      +b.scrollable-wrapper--till-2xl.--full
        +b.TABLE.table--variant_scroll.--space_lg
          +e.THEAD.thead
            +e.TR.row--relief
              +e.TH.cell--variant_bold.--head.--bordered_left_top.--bordered_left_bottom
                row(alignment='center' space='md' nowrap)
                  cell
                    v-checkbox
                      +b.INPUT(type="checkbox"
                              v-model='allSelected'
                              @input='allSelected = !allSelected;selectItems(allSelected)').control-checkbox__input
                  
                  cell
                    v-caption(
                      color='white'
                      size='6'
                    ) {{ _('u Customer name') }}
              +e.TH.cell--variant_bold.--head 
                v-caption(
                  size='6' 
                  color='white' 
                  variant='bold'
                ) {{ _('c Email') }}
              +e.TH.cell--variant_bold.--head 
                v-caption(
                  size='6' 
                  color='white' 
                  variant='bold'
                ) {{ _('c Phone number') }}
              +e.TH.cell--variant_bold.--head 
                v-caption(
                  size='6' 
                  color='white' 
                  variant='bold'
                ) {{ _('c City') }}
              +e.TH.cell--variant_bold.--head 
                v-caption(
                  size='6' 
                  color='white' 
                  variant='bold'
                ) {{ _('c State/Province') }}
              +e.TH.cell--variant_bold.--head 
                v-caption(
                  size='6' 
                  color='white' 
                  variant='bold'
                ) {{ _('c Country') }}
              +e.TH.cell--variant_bold.--head 
                v-caption(
                  size='6' 
                  color='white' 
                  variant='bold'
                ) {{ _('c Orders') }}
              +e.TH.cell--variant_bold.--head.--bordered_right_top.--bordered_right_bottom 
                v-caption(
                  size='6' 
                  color='white' 
                  variant='bold'
                ) {{ _('c Amount') }}

                cell
                  v-caption(color='white', size='6') {{ _("u Customer name") }}
            +e.TH.cell--variant_bold.--head 
              v-caption(size='6', color='white', variant='bold') {{ _("c Email") }}
            +e.TH.cell--variant_bold.--head 
              v-caption(size='6', color='white', variant='bold') {{ _("c Phone number") }}
            +e.TH.cell--variant_bold.--head 
              v-caption(size='6', color='white', variant='bold') {{ _("c City") }}
            +e.TH.cell--variant_bold.--head 
              v-caption(size='6', color='white', variant='bold') {{ _("c State/Province") }}
            +e.TH.cell--variant_bold.--head 
              v-caption(size='6', color='white', variant='bold') {{ _("c Country") }}
            +e.TH.cell--variant_bold.--head 
              v-caption(size='6', color='white', variant='bold') {{ _("c Orders") }}
            +e.TH.cell--variant_bold.--head.--bordered_right_top.--bordered_right_bottom 
              v-caption(size='6', color='white', variant='bold') {{ _("c Amount") }}

        UserItem(v-for='user in users', :key='user.id', :user='user')
          template(v-slot:checkbox='')
            v-checkbox
              +b.INPUT.control-checkbox__input(type='checkbox', :value='user.id', v-model='selected')
          template(v-slot:address='')
            v-caption(size='6') {{ user.address }}
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { useToggleSelect } from '@cabinet/composables/use-selected'
import { wrap } from '@cabinet/composables/utils'
import UserItem from '@cabinet/components/users/UserItem'
export default {
  components: {
    UserItem,
  },
  props: {
    users: {
      required: true,
      type: Array
    },
    actionDelete: {
      type: Boolean
    },
  },
  setup(p, {emit}) {
    const selected = ref([])
    const localUsers = ref([])
    const allSelected = ref(false)

    const selectItems = value => {
      selected.value = useToggleSelect(wrap(localUsers), value)
      emit('input', selected.value)
    }

    watch(
      selected,
      (v) => {
        // if (v.length != users.length) {
        //   allSelected.value = !allSelected.value
        // }
        emit('input', v)
      }
    )
    
    watch(
      'actionDelete',
      () => {
        selected.value = []
        allSelected.value = false
      },
    )

    watch(
      'users',
      (v) => {
        localUsers.value = v
      },
    )
    return {
      allSelected,
      selected,
      selectItems,
      localUsers
    }
  }
}
</script>